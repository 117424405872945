<template>
  <t-card class="h-full flex flex-col justify-center">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">
      Pay with Bitcoin
    </h1>
    <notification />
    <div class="my-2 overflow-auto text-center">
      <div class="flex flex-col w-full justify-between space-y-10">
        <div class="flex flex-col space-x-6">
          <div class="flex flex-row mb-4 bg-gray-100 rounded-full">
            <button
              class="
                flex-1
                bg-gray-100
                py-2
                px-4
                text-center
                font-semibold
                text-sm
                focus:outline-none
                border-0
                focus:bg-gray-300 focus:rounded-full
                rounded-full
              "
              :class="{ 'bg-gray-300 rounded-full': chain === 'MAIN' }"
              @click="activateChain('MAIN')"
            >
              Main Chain
            </button>
            <button
              class="
                flex-1
                bg-gray-100
                py-2
                px-4
                text-center
                font-semibold
                text-sm
                focus:outline-none
                border-0
                focus:bg-gray-300 focus:rounded-full
                rounded-full
              "
              :class="{ 'bg-gray-300 rounded-full': chain === 'LIGHTNING' }"
              @click="activateChain('LIGHTNING')"
            >
              Lightning
            </button>
          </div>
          <div class="flex flex-col justify-center text-center">
            <div
              v-if="amount === 0 && !service_id"
              class="text-center space-y-2 my-6"
            >
              <p class="text-indigo-600 font-semibold">Please wait</p>
              <p class="text-sm text-gray-500">Do not close popup</p>
            </div>
            <div
              v-if="chain === 'MAIN' && amount > 0"
              class="flex flex-col space-y-4"
            >
              <h3 class="text-xl">Pay {{ amount | stashToBtc }} BTC</h3>
              <h5 class="text-sm text-gray-500">~= {{ fiat_value }} USD</h5>
              <h4 class="text-lg text-gray-600">
                Payment for USD {{ fiat_value }}
              </h4>
              <div>
                <a
                  :href="btcUri"
                  target="_blank"
                  class="
                    btn-gradient
                    shadow
                    text-white
                    leading-tight
                    text-center text-sm
                    uppercase
                    rounded
                    focus:outline-none
                    px-4
                    py-3
                    font-bold
                    bg-indigo-500
                  "
                >
                  Open in Wallet
                </a>
              </div>
              <div class="flex justify-center">
                <QRCanvas
                  :options="mainChainQROptions"
                  v-if="address"
                  class="h-56"
                />
              </div>
              <div class="text-sm uppercase w-full overflow-auto">Address: {{ address }}</div>
            </div>
            <div
              v-if="chain === 'LIGHTNING' && amount > 0"
              class="flex flex-col space-y-4"
            >
              <h3 class="text-xl">Pay {{ amount | stashToBtc }} BTC</h3>
              <h5 class="text-sm text-gray-500">~= {{ fiat_value }} USD</h5>
              <h4 class="text-lg text-gray-600">
                Payment for USD {{ fiat_value }}
              </h4>
              <div>
                <a
                  :href="'lightning:' + lnd_payreq"
                  target="_blank"
                  class="
                    btn-gradient
                    shadow
                    text-white
                    leading-tight
                    text-center text-sm
                    uppercase
                    rounded
                    focus:outline-none
                    px-4
                    py-3
                    font-bold
                    bg-indigo-500
                  "
                >
                  Open in Wallet
                </a>
              </div>
              <div class="flex justify-center">
                <QRCanvas
                  :options="lndChainQROptions"
                  v-if="lnd_payreq"
                  class="h-56"
                />
              </div>
              <div class="text-sm uppercase w-full overflow-auto">PayReq: {{ lnd_payreq }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </t-card>
</template>
<script>
import axios from "axios";
import { QRCanvas } from "qrcanvas-vue";
import * as Sentry from "@sentry/vue";
import utils from "../utils";

import intasendLogo from "./../assets/intasend-min-logo.png";
import Notification from "./Notification";

export default {
  name: "BitcoinPaymentCompletePay",
  components: {
    QRCanvas,
    Notification,
  },
  data() {
    return {
      chain: "MAIN",
      invoice_id: null,
      service_id: null,
      status: "unpaid",
      amount: 0,
      fiat_value: 0,
      uri: "",
      lnd_payreq: "",
      address: "",
      chain_address: "",
      ttl: 1440,
      polling: false,
      bitcoin_qr: "",
      forcePolling: false,
      mainChainQROptions: {
        cellSize: 10,
        correctLevel: "H",
      },
      lndChainQROptions: {
        cellSize: 10,
        correctLevel: "H",
      },
    };
  },
  filters: {
    stashToBtc(value) {
      return (parseFloat(value) * 0.00000001).toFixed(8);
    },
  },
  watch: {
    address(newVal) {
      if (newVal) {
        let amount = (parseFloat(this.amount) * 0.00000001).toFixed(8);
        this.mainChainQROptions["data"] =
          "bitcoin:" + this.address + "?amount=" + amount;
      }
    },
    lnd_payreq(newVal) {
      if (newVal) {
        this.lndChainQROptions["data"] = this.lnd_payreq;
      }
    },
  },
  computed: {
    btcUri() {
      if (!this.uri) return "";
      return this.uri.split("&lightning")[0];
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  mounted() {
    this.monitorResults();
  },
  created() {
    const image = new Image();
    image.src = intasendLogo;
    image.onload = () => {
      this.mainChainQROptions = Object.assign({}, this.mainChainQROptions, {
        logo: {
          image,
        },
      });
      this.lndChainQROptions = Object.assign({}, this.lndChainQROptions, {
        logo: {
          image,
        },
      });
    };
  },
  methods: {
    activateChain(chain) {
      this.chain = chain;
    },
    monitorResults() {
      let self = this;
      this.polling = setInterval(() => {
        self.getInitDetails();
        if (
          self.service_id &&
          (self.status === "paid" || self.status === "expired")
        ) {
          clearInterval(self.polling);
          self.$router.push(
            "/results/" + self.invoice_id
          );
        }
      }, 5000);
    },
    getInitDetails() {
      let self = this;
      let payload = this.$store.getters.getBitcoinPayload;
      axios
        .post("payment/collection/bitcoin-get-status/", payload)
        .then((resp) => {
          // loader.hide();
          self.status = resp.data.status;
          if (!self.service_id) {
            self.service_id = resp.data.service_id;
            self.address = resp.data.address;
            self.uri = resp.data.uri;
            self.amount = resp.data.amount;
            self.fiat_value = resp.data.fiat_value;
            self.chain_address = resp.data.chain_address;
            self.invoice_id = resp.data.invoice.invoice_id;
            if (resp.data.lnd_invoice_payreq) {
              self.lnd_payreq = resp.data.lnd_invoice_payreq.toUpperCase();
            }
          }
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          Sentry.captureException({
            resp: err,
            channel: "BITCOIN",
          });
          clearInterval(self.polling);
        });
    },
  },
};
</script>