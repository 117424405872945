import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import DefaultPage from "./../components/DefaultPage"
import ResultsPage from "./../components/ResultsPage"
import MPesaPaybillPage from "./../components/MPesaPaybillPage"
import ThreeDSDeviceCollectionPage from "./../components/ThreeDSDeviceCollectionPage"
import ThreeDSCompleteSetupPage from "./../components/ThreeDSCompleteSetupPage"
import BitcoinPaymentCompletePay from "./../components/BitcoinPaymentCompletePay"

const routes = [{
    path: '/',
    component: DefaultPage
}, {
    path: '/results/:id',
    component: ResultsPage
}, {
    path: '/mpesa-paybill/:id',
    component: MPesaPaybillPage
}, {
    path: '/3ds-device-collection/',
    component: ThreeDSDeviceCollectionPage
}, {
    path: '/3ds-complete/',
    component: ThreeDSCompleteSetupPage
}, {
    path: '/bitcoin-complete/',
    component: BitcoinPaymentCompletePay
}]


export
default new VueRouter({
    mode: 'history',
    routes
})