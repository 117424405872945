var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "min:h-full bg-white border-gray-300 hover:bg-light-100" },
    [
      _vm.title
        ? _c(
            "div",
            { staticClass: "px-0 lg:px-1 pt-2 flex justify-between" },
            [
              _c("h4", { staticClass: "text-xl" }, [_vm._v(_vm._s(_vm.title))]),
              _vm._t("actions"),
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "px-2 py-4" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }