var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bitcoin-payment" },
    [
      _c(
        "h1",
        { staticClass: "text-xl font-semibold text-gray-700 my-6 text-center" },
        [_vm._v(" Pay with Bitcoin (or Cash App BTC) ")]
      ),
      _c("notification"),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          !_vm.hasDefaultEmail
            ? _c("div", { staticClass: "mb-3" }, [
                _c("div", [_c("t-label", [_vm._v("Email")])], 1),
                _c(
                  "div",
                  [
                    _c("t-input", {
                      attrs: { type: "email", placeholder: "Enter email" },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                    _vm.validation.hasError("form.email")
                      ? _c("div", { staticClass: "text-red-600 text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.validation.firstError("form.email")) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "mb-2" }, [
            _c("div", [_c("t-label", [_vm._v("Amount")])], 1),
            _c(
              "div",
              [
                _c("t-input", {
                  attrs: {
                    type: "number",
                    placeholder: "Amount in " + _vm.form.currency,
                    disabled: _vm.hasDefaultAmount,
                  },
                  model: {
                    value: _vm.form.amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "amount", $$v)
                    },
                    expression: "form.amount",
                  },
                }),
                _vm.validation.hasError("form.amount")
                  ? _c("div", { staticClass: "text-red-600 text-sm" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.validation.firstError("form.amount")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          !_vm.hasDefaultAPIRef
            ? _c("div", { staticClass: "mb-2" }, [
                _c("div", [_c("t-label", [_vm._v("Reference")])], 1),
                _c(
                  "div",
                  [
                    _c("t-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.form.api_ref,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "api_ref", $$v)
                        },
                        expression: "form.api_ref",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.charges > 0
            ? _c(
                "div",
                { staticClass: "mb-2 bg-gray-100 p-2 flex justify-between" },
                [
                  _c("t-label", [_vm._v("Payment fee")]),
                  _c("t-label", [
                    _vm._v(
                      _vm._s(_vm.form.currency) + " " + _vm._s(_vm.charges)
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pt-2" },
            [
              _c("t-button", [
                _vm._v(" Pay "),
                _vm.form.currency && _vm.total
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.form.currency) + " " + _vm._s(_vm.total)
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }