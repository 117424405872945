var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col" }, [
    _vm.result.invoice.state == "PENDING"
      ? _c(
          "div",
          { staticClass: "p-2 mb-2 bg-blue-600 rounded text-blue-100" },
          [
            _vm._v(" Please check "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.result.invoice.account)),
            ]),
            _vm._v(" for payment request. "),
          ]
        )
      : _vm._e(),
    _vm.result.invoice.state == "FAILED"
      ? _c("div", { staticClass: "p-2 mb-2 bg-red-600 rounded text-red-100" }, [
          _vm._v(" " + _vm._s(_vm.result.invoice.failed_reason) + " "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "flex py-1" }, [
      _c("div", { staticClass: "w-1/2" }, [_vm._v("Provider")]),
      _c("div", { staticClass: "w-1/2 text-right" }, [
        _vm._v(_vm._s(_vm.result.invoice.provider)),
      ]),
    ]),
    _c("div", { staticClass: "flex py-1" }, [
      _c("div", { staticClass: "w-1/2" }, [_vm._v("Phone number")]),
      _c("div", { staticClass: "w-1/2 text-right" }, [
        _vm._v(_vm._s(_vm.result.invoice.account)),
      ]),
    ]),
    _c("div", { staticClass: "flex py-1" }, [
      _c("div", { staticClass: "w-1/2" }, [_vm._v("Status")]),
      _c("div", { staticClass: "w-1/2 text-right" }, [
        _vm._v(_vm._s(_vm.result.invoice.state)),
      ]),
    ]),
    _c("div", { staticClass: "flex py-1" }, [
      _c("div", { staticClass: "w-1/2" }, [_vm._v("Updated at")]),
      _c("div", { staticClass: "w-1/2 text-right" }, [
        _vm._v(_vm._s(_vm._f("formatDate")(_vm.result.invoice.updated_at))),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }