var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "t-card",
    { staticClass: "h-full flex flex-col justify-center" },
    [
      _c(
        "h1",
        { staticClass: "text-xl font-semibold text-gray-700 my-6 text-center" },
        [_vm._v(" M-Pesa Paybill Instruction ")]
      ),
      _c("notification"),
      _c("div", { staticClass: "mb-2 mt-6 overflow-auto" }, [
        _c("div", { staticClass: "flex flex-col w-full space-y-2" }, [
          _c("div", { staticClass: "flex flex-row justify-between" }, [
            _c("div", { staticClass: "font-semibold" }, [
              _vm._v("Pay Bill Number"),
            ]),
            _c("div", { staticClass: "font-semibold" }, [_vm._v("4030517")]),
          ]),
          _c("div", { staticClass: "flex flex-row justify-between" }, [
            _c("div", { staticClass: "font-semibold" }, [
              _vm._v("Account Number"),
            ]),
            _c("div", { staticClass: "font-semibold" }, [
              _vm._v(_vm._s(_vm._f("lowerCase")(_vm.invoiceID))),
            ]),
          ]),
          _vm.amount
            ? _c("div", { staticClass: "flex flex-row justify-between" }, [
                _c("div", { staticClass: "font-semibold" }, [_vm._v("Amount")]),
                _c("div", { staticClass: "font-semibold" }, [
                  _vm._v("KES " + _vm._s(_vm.amount)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "p-2 bg-blue-200" }, [
            _vm._v(
              " Go to your Lipa na M-Pesa, select Pay Bill and use the above information to complete payment. Then click confirm payment below to check for status. "
            ),
          ]),
          _c(
            "div",
            { staticClass: "pt-2", on: { click: _vm.checkResults } },
            [_c("t-button", [_vm._v(" Confirm Payment ")])],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }