<template>
  <span>
    <button
      v-if="!link"
      :class="btnClass"
      :type="type ? type : 'submit'"
      @click="$emit('click')"
      :disabled="disabled"
      class="
        btn-gradient
        shadow
        text-white
        leading-tight
        text-center text-sm
        uppercase
        block
        w-full
        rounded
        focus:outline-none
        px-4
        py-3
        font-bold
      "
    >
      <span class="m-auto">
        <slot></slot>
      </span>
    </button>
    <router-link
      v-if="link"
      :class="btnClass"
      :to="link"
      class="
        btn-gradient
        shadow
        text-white
        leading-tight
        text-center text-sm
        uppercase
        block
        w-full
        rounded
        focus:outline-none
        px-4
        py-3
        font-bold
      "
    >
      <slot></slot>
    </router-link>
  </span>
</template>

<script>
export default {
  name: "TButton",
  props: {
    variant: {
      required: false,
    },
    link: {
      required: false,
    },
    type: {
      required: false,
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  computed: {
    btnClass() {
      if (this.variant === "light") {
        return "w-full bg-gray-100 text-gray-900 hover:bg-gray-200";
      }
      return "w-full bg-blue-600 hover:bg-blue-700 text-blue-100";
    },
  },
};
</script>
<style scoped>
.btn-gradient {
  background-image: linear-gradient(150deg, #0ec9e6, #4e78fb);
}
</style>