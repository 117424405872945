var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    ref: "inputField",
    staticClass: "py-2 px-3 w-full block focus:outline-none border rounded",
    class: _vm.extraClass,
    attrs: { type: _vm.type, placeholder: _vm.placeholder },
    on: {
      input: function ($event) {
        return _vm.updateValue()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }