<template>
  <div id="app" class="h-screen overflow-auto flex rounded">
    <div class="w-full lg:w-1/3 lg:m-auto">
      <div class="bg-gray-200 lg:p-3 p-2 rounded">
        <router-view class="rounded"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
</style>
