<template>
  <t-card class="h-full flex flex-col justify-center">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">
      M-Pesa Paybill Instruction
    </h1>
    <notification />
    <div class="mb-2 mt-6 overflow-auto">
      <div class="flex flex-col w-full space-y-2">
        <div class="flex flex-row justify-between">
          <div class="font-semibold">Pay Bill Number</div>
          <div class="font-semibold">4030517</div>
        </div>
        <div class="flex flex-row justify-between">
          <div class="font-semibold">Account Number</div>
          <div class="font-semibold">{{ invoiceID|lowerCase }}</div>
        </div>
        <div class="flex flex-row justify-between" v-if="amount">
          <div class="font-semibold">Amount</div>
          <div class="font-semibold">KES {{ amount }}</div>
        </div>
        <div class="p-2 bg-blue-200">
          Go to your Lipa na M-Pesa, select Pay Bill and use the above
          information to complete payment. Then click confirm payment below to
          check for status.
        </div>
        <div class="pt-2" @click="checkResults">
          <t-button> Confirm Payment </t-button>
        </div>
      </div>
    </div>
  </t-card>
</template>
<script>
import Notification from "./Notification";

export default {
  name: "MPesaPaybillPage",
  components: {
    Notification,
  },
  data() {
    return {};
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  filters: {
    lowerCase(val){
      return val.toLowerCase()
    }
  },
  computed: {
    invoiceID() {
      return this.$route.params.id;
    },
    amount() {
      return this.$route.query.amount;
    },
  },
  methods: {
    checkResults() {
      this.$router.push("/results/" + this.invoiceID);
    },
  },
};
</script>