<template>
  <div class="my-2" v-if="notifications.length > 0">
    <div
      class="px-4 py-2 text-gray-100 rounded"
      v-for="(notification, index) in notifications"
      :key="index"
      :class="{'bg-red-500 border-red-500':notification.type=='error', 'bg-indigo-600 border-indigo-600':notification.type=='info'}"
    >{{notification.text}}</div>
  </div>
</template>

<script>
export default {
  name: "Notifications",
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    }
  },
  created() {
    // Clear all notifications
    this.$store.commit("clearNotification");
  }
};
</script>