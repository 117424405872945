<template>
  <t-card class="">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">Payment status</h1>
    <notification />
    <div class="my-2 overflow-auto">
      <m-pesa-results-page
        :result.sync="result"
        v-if="result.invoice.provider == 'M-PESA'"
      />
      <card-results-page
        :result.sync="result"
        v-if="result.invoice.provider == 'CARD-PAYMENT'"
      />
      <card-results-page
        :result.sync="result"
        v-if="result.invoice.provider == 'BITCOIN'"
      />
    </div>
    <div
      class="mt-4"
      v-if="
        result.invoice.state != 'COMPLETE' && result.invoice.state != 'FAILED'
      "
    >
      <t-light-button variant="light" @click="getResult">Refresh Status</t-light-button>
    </div>
    <div
      class="py-2 text-center font-semibold"
      v-if="result.invoice.state == 'FAILED'"
    >
      <!-- <button
        @click="resetBtn"
        class="
          bg-gray-200
          border-gray-300
          px-3
          py-2
          rounded
          shadow
          focus:outline-none
        "
        v-if="$store.getters.getShowAlternatives"
      >
        <div class="flex flex-row space-x-1 space-between">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-600"
              style="margin-top: 1.5px"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
              /></svg
          ></span>
          <span class="text-sm">Change payment method</span>
        </div>
      </button> -->
    </div>
    <div
      class="mt-4 py-2 text-center block lg:hidden"
      v-if="$store.getters.deviceIsMobile"
    >
      <button
        class="
          bg-gray-200
          md:hidden
          border-gray-300
          px-3
          py-2
          rounded
          shadow
          focus:outline-none
        "
        @click="closeModal"
      >
        <div class="flex flex-row space-x-1 space-between">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-600"
              style="margin-top: 1.5px"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              /></svg
          ></span>
          <span class="text-sm">Close payment</span>
        </div>
      </button>
    </div>
  </t-card>
</template>

<script>
import axios from "axios";
import Notification from "./Notification";
import MPesaResultsPage from "./MPesaResultsPage";
import CardResultsPage from "./CardResultsPage";
import utils from "../utils";

export default {
  name: "ResultsPage",
  data() {
    return {
      result: {
        invoice: {},
        polling: null,
      },
    };
  },
  components: {
    Notification,
    MPesaResultsPage,
    CardResultsPage,
  },
  computed: {
    invoiceID() {
      return this.$route.params.id;
    },
  },
  watch: {
    "result.invoice.state": function (newVal) {
      if (newVal) {
        let event_payload = {
          tracking_id: this.result.invoice.invoice_id,
          state: newVal,
          provider: this.result.invoice.provider,
          value: this.result.invoice.value,
          net_amount: this.result.invoice.net_amount,
          charges: this.result.invoice.charges,
          currency: this.result.invoice.currency,
          account: this.result.invoice.account,
          api_ref: this.result.invoice.api_ref,
          failed_reason: this.result.invoice.failed_reason,
          failed_code: this.result.invoice.failed_code,
          host: this.result.invoice.host,
          created_at: this.result.invoice.created_at,
          updated_at: this.result.invoice.updated_at,
          meta: this.result.meta,
          identitier: "intasend-status-update-cdrtl",
        };
        try {
          window.parent.postMessage(
            {
              message: event_payload,
            },
            "*"
          );
        } catch (ex) {
          console.error(ex);
        }
      }
    },
  },
  created() {
    this.getResult();
    this.monitorResults();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    monitorResults() {
      this.polling = setInterval(() => {
        if (
          this.result.invoice.state == "PENDING" ||
          this.result.invoice.state == "PROCESSING"
        ) {
          this.getResult();
        }
      }, 5000);
    },
    loadAuthDetails(payload) {
      let checkoutKeys = this.$store.getters.getCheckoutKeys;
      if (checkoutKeys.signature && checkoutKeys.checkoutID) {
        payload.signature = checkoutKeys.signature;
        payload.checkout_id = checkoutKeys.checkoutID;
      } else {
        payload.public_key = this.$store.getters.getPublicKey;
      }
      return payload;
    },
    getResult() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = { invoice_id: this.invoiceID };
      payload = this.loadAuthDetails(payload);

      axios
        .post("payment/status/", payload)
        .then((resp) => {
          loader.hide();
          self.result = resp.data;
          if (self.result){
            let invoice = self.result.invoice;
            if(invoice){
              if (invoice.state === "RETRY") {
                self.$store.commit("setInvoice", invoice);
                this.$store.commit("setPayInfo", this.$store.getters.getDefaultItems)
                self.$router.push("/?method="+invoice.provider);
              }
            }
          }
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          loader.hide();
        });
    },
    closeModal() {
      try {
        window.parent.postMessage(
          {
            message: { identitier: "intasend-close-modal-cdrtl" },
          },
          "*"
        );
      } catch (err) {
        console.err(err);
      }
    },
    objectToParam(obj) {
      var str = [];
      let item = "";
      for (var p in obj) item = encodeURIComponent(p);
      item.concat("=", encodeURIComponent(obj[p]));
      str.push(item);
      return str.join("&");
    },
    resetBtn() {
      this.$router.push("/");
    },
  },
};
</script>
