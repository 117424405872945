<template>
  <div class="bitcoin-payment">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">
      Pay with Bitcoin (or Cash App BTC)
    </h1>
    <notification />
    <form @submit.prevent="onSubmit">
      <div class="mb-3" v-if="!hasDefaultEmail">
        <div>
          <t-label>Email</t-label>
        </div>
        <div>
          <t-input
            type="email"
            v-model="form.email"
            placeholder="Enter email"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.email')"
          >
            {{ validation.firstError("form.email") }}
          </div>
        </div>
      </div>
      <div class="mb-2">
        <div>
          <t-label>Amount</t-label>
        </div>
        <div>
          <t-input
            type="number"
            :placeholder="'Amount in ' + form.currency"
            v-model="form.amount"
            :disabled="hasDefaultAmount"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.amount')"
          >
            {{ validation.firstError("form.amount") }}
          </div>
        </div>
      </div>
      <div class="mb-2" v-if="!hasDefaultAPIRef">
        <div>
          <t-label>Reference</t-label>
        </div>
        <div>
          <t-input type="text" v-model="form.api_ref" />
        </div>
      </div>
      <div class="mb-2 bg-gray-100 p-2 flex justify-between" v-if="charges > 0">
        <t-label>Payment fee</t-label>
        <t-label>{{ form.currency }} {{ charges }}</t-label>
      </div>
      <div class="pt-2">
        <t-button>
          Pay
          <span v-if="form.currency && total"
            >{{ form.currency }} {{ total }}</span
          >
        </t-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import SimpleVueValidation from "simple-vue-validator";
import * as Sentry from "@sentry/vue";
const Validator = SimpleVueValidation.Validator;
import utils from "../utils";

import Notification from "./Notification";

export default {
  name: "BitcoinPaymentPage",
  data() {
    return {
      defaultItems: {},
      form: {
        method: "BITCOIN",
        email: "",
        api_ref: "",
        amount: 0,
        name: "",
        currency: "USD",
      },
      total: 0,
    };
  },
  props: {
    deviceFingerprint: {
      required: false,
    },
  },
  components: {
    Notification,
  },
  computed: {
    hasDefaultAmount() {
      if (!this.defaultItems) return false;
      if (
        this.defaultItems.amount === "" ||
        this.defaultItems.amount === undefined
      ) {
        return false;
      }
      if (this.defaultItems.amount) {
        if (parseInt(this.defaultItems.amount) === 0) {
          return false;
        }
      }
      return this.defaultItems.amount;
    },
    hasDefaultAPIRef() {
      if (!this.defaultItems) return false;
      return this.defaultItems.api_ref;
    },
    hasDefaultEmail() {
      if (!this.defaultItems) return false;
      return this.defaultItems.email;
    },
    charges() {
      return Math.round((this.total - this.form.amount) * 100) / 100;
    },
  },
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
    "form.amount": function (value) {
      return Validator.value(value).required().float().between(0.1, 5000);
    },
  },
  mounted() {
    this.defaultItems = this.$store.getters.getDefaultItems;
    this.form.phone_number = this.defaultItems.phone_number || "";
    this.form.amount = this.defaultItems.amount || "";
    this.form.api_ref = this.defaultItems.api_ref || "";
    this.form.email = this.defaultItems.email || "";
    this.form.first_name = this.defaultItems.first_name || "";
    this.form.last_name = this.defaultItems.last_name || "";
    this.form.comment = this.defaultItems.comment || "";
    this.form.callback_url = this.defaultItems.callback_url || "";
    this.form.currency = this.defaultItems.currency || "USD";
    this.form.mobile_tarrif =
      this.defaultItems.mobile_tarrif || "BUSINESS-PAYS";
    this.form.host = this.defaultItems.host;
    this.form.wallet_id = this.defaultItems.wallet_id || "";
  },
  watch: {
    "form.amount"(newVal) {
      if (newVal) {
        this.getTotal();
      }
    },
  },
  methods: {
    loadAuthDetails(payload) {
      let checkoutKeys = this.$store.getters.getCheckoutKeys;
      if (checkoutKeys.signature && checkoutKeys.checkoutID) {
        payload.signature = checkoutKeys.signature;
        payload.checkout_id = checkoutKeys.checkoutID;
      } else {
        payload.public_key = this.$store.getters.getPublicKey;
      }
      return payload;
    },
    sendRequest() {
      let self = this;
      let loader = self.$loading.show();

      this.form = this.loadAuthDetails(this.form);
      this.form.device_fingerprint = this.deviceFingerprint;
      var payload = JSON.parse(JSON.stringify(this.form));
      axios
        .post("payment/collection/", payload)
        .then((resp) => {
          loader.hide();
          let invoice_id = resp.data.invoice.invoice_id;
          self.$store.commit("setBitcoinPayload", {
            signature: resp.data.signature,
            reference: invoice_id,
          });
          self.$router.push("/bitcoin-complete/");
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          loader.hide();
          Sentry.captureException({
            resp: JSON.stringify(err),
            amount: self.form.amount,
            email: self.form.email,
            host: self.host,
            businessID: self.form.public_key,
            channel: "BITCOIN",
          });
        });
    },
    onSubmit() {
      let self = this;
      self.$validate().then((success) => {
        if (success) {
          self.sendRequest();
        }
      });
    },
    getTotal() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = {
        tarrif: this.form.card_tarrif,
        method: "BITCOIN",
        currency: this.form.currency,
        amount: this.form.amount,
        public_key: this.$store.getters.getPublicKey,
      };
      axios
        .post("payment/get_amount_estimate/", payload)
        .then((resp) => {
          loader.hide();
          self.total = resp.data.total;
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          loader.hide();
          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
  },
};
</script>