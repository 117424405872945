<template>
  <t-card v-if="ready">
    <ChoosePayment
      v-if="!payment_method"
      :payment-method.sync="payment_method" 
      :payment-default-settings.sync="payment_default_settings"
    />
    <MPesaPaymentPage
      :deviceFingerprint.sync="device_fingerprint"
      v-if="payment_method === 'M-PESA'"
    />
    <CardPaymentPage
      :deviceFingerprint.sync="device_fingerprint"
      v-if="payment_method === 'CARD-PAYMENT'"
    />
    <BitcoinPaymentPage
      :deviceFingerprint.sync="device_fingerprint"
      v-if="payment_method === 'BITCOIN'"
    />
    <div
      class="pt-4 pb-2 text-center"
      v-if="payment_method && $store.getters.getShowAlternatives"
    >
      <button
        class="
          bg-gray-200
          border-gray-300
          px-3
          py-2
          rounded
          shadow
          focus:outline-none
        "
        @click="clearPaymentMethod"
      >
        <div class="flex flex-row space-x-1 space-between">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-600"
              style="margin-top: 1.5px"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z"
              /></svg
          ></span>
          <span class="text-sm">Change payment method</span>
        </div>
      </button>
    </div>
    <div
      class="py-2 text-center block lg:hidden"
      v-if="$store.getters.deviceIsMobile"
    >
      <button
        class="
          bg-gray-200
          border-gray-300
          px-3
          py-2
          rounded
          shadow
          focus:outline-none
        "
        @click="closeModal"
      >
        <div class="flex flex-row space-x-1 space-between">
          <span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 text-gray-600"
              style="margin-top: 1.5px"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              /></svg
          ></span>
          <span class="text-sm">Cancel Payment</span>
        </div>
      </button>
    </div>
    <div class="py-4 flex flex-col copyright">
      <a
        href="https://intasend.com"
        target="_blank"
        class="text-xs text-gray-500 flex self-center"
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div>Secured by <span class="font-semibold">IntaSend</span></div></a
      >
    </div>
  </t-card>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";
import Fingerprint2 from "fingerprintjs2";
import ChoosePayment from "./ChoosePayment.vue";
import MPesaPaymentPage from "./MPesaPaymentPage.vue";
import CardPaymentPage from "./CardPaymentPage.vue";
import BitcoinPaymentPage from "./BitcoinPaymentPage.vue";
import utils from "../utils";

export default {
  name: "DefaultPage",
  data() {
    return {
      device_fingerprint: {},
      payment_method: "",
      ready: false,
      payment_default_settings: {}
    };
  },
  components: {
    ChoosePayment,
    MPesaPaymentPage,
    CardPaymentPage,
    BitcoinPaymentPage
  },
  created() {
    let payInfo = this.$store.getters.getPayInfo
    let invoice = this.$store.getters.getInvoice
   
    if (payInfo && invoice) {
      if (payInfo.method && invoice.state === 'RETRY'){
        if (payInfo.checkout_id && payInfo.signature) {
          this.$store.commit("setCheckoutKeys", {
            checkoutID: payInfo.checkout_id,
            signature: payInfo.signature,
          });
        }

        if (payInfo) {
          this.$store.commit("setDefaultItems", payInfo);
        }

        if (payInfo.host) {
          this.$store.commit("setPluginHost", payInfo.host);
        }

        if (payInfo.public_key) {
          this.$store.commit("setPublicKey", payInfo.public_key);
        }

        if (payInfo.method) {
          this.payment_method = payInfo.method;
        }

        if (payInfo.device_fingerprint) {
          this.device_fingerprint = payInfo.device_fingerprint
        }
        
        this.$store.commit("setShowAlternatives", false);
        this.ready = true;
        return
      }
    }

    if (this.$route.query.is_mobile === "true") {
      this.$store.commit("setIsMobile", true);
    }

    if (this.$route.query.method) {
      if (this.$route.query.method.toUpperCase() !== "ALL") {
        this.payment_method = this.$route.query.method.toUpperCase();
        this.$store.commit("setShowAlternatives", false);
      }
    }

    if (this.$route.query.checkout) {
      let checkoutData = JSON.parse(atob(this.$route.query.checkout));
      this.$store.commit("setCheckoutKeys", {
        checkoutID: checkoutData.checkout_id,
        signature: checkoutData.signature,
      });
      this.getCheckoutDefaults();
    } else {
      this.$store.commit("setDefaultItems", this.$route.query);
      this.$store.commit("setPluginHost", this.$route.host);
      this.ready = true;
    }

    if (this.$route.query.public_key) {
      this.$store.commit("setPublicKey", this.$route.query.public_key);
    }

    this.requestDeviceFingerprint();
  },
  methods: {
    clearPaymentMethod() {
      this.payment_method = "";
    },
    getCheckoutDefaults() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });

      let checkoutKeys = this.$store.getters.getCheckoutKeys;
      let payload = {
        signature: checkoutKeys.signature,
        checkout_id: checkoutKeys.checkoutID,
      };
      axios
        .post("checkout/details/", payload)
        .then((resp) => {
          loader.hide();
          var defaultPayload = resp.data
          if (!defaultPayload.callback_url || defaultPayload.callback_url ==="undefined") {
            defaultPayload.callback_url = ""
          }
          self.$store.commit("setDefaultItems", defaultPayload);

          // Update methods to show case
          if (resp.data.method) {
            self.$store.commit("setShowAlternatives", false);
            if (resp.data.method.toUpperCase() !== "ALL") {
              self.payment_method = resp.data.method.toUpperCase();
            }
          }
          if (resp.data.defaults) {
            self.payment_default_settings = resp.data.defaults
          }
          self.ready = true;
        })
        .catch((err) => {
          loader.hide();
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          Sentry.captureException({
            resp: err,
            channel: "CARD-PAYMENT",
          });
        });
    },
    requestDeviceFingerprint() {
      try {
        let self = this;
        let options = {
          excludes: {
            plugins: true,
            canvas: true,
            webgl: true,
            webglVendorAndRenderer: true,
            audio: true,
            fonts: true,
            fontsFlash: true,
            enumerateDevices: true,
          },
        };
        if (window.requestIdleCallback) {
          requestIdleCallback(function () {
            Fingerprint2.get(options, function (components) {
              self.device_fingerprint = components;
            });
          });
        } else {
          setTimeout(function () {
            Fingerprint2.get(options, function (components) {
              self.device_fingerprint = components;
            });
          }, 500);
        }
      } catch (err) {
        console.error(err);
      }
    },
    closeModal() {
      window.parent.postMessage(
        {
          message: { identitier: "intasend-close-modal-cdrtl" },
        },
        "*"
      );
    },
  },
};
</script>

<style>
</style>
