var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "t-card",
        [
          !_vm.payment_method
            ? _c("ChoosePayment", {
                attrs: {
                  "payment-method": _vm.payment_method,
                  "payment-default-settings": _vm.payment_default_settings,
                },
                on: {
                  "update:paymentMethod": function ($event) {
                    _vm.payment_method = $event
                  },
                  "update:payment-method": function ($event) {
                    _vm.payment_method = $event
                  },
                  "update:paymentDefaultSettings": function ($event) {
                    _vm.payment_default_settings = $event
                  },
                  "update:payment-default-settings": function ($event) {
                    _vm.payment_default_settings = $event
                  },
                },
              })
            : _vm._e(),
          _vm.payment_method === "M-PESA"
            ? _c("MPesaPaymentPage", {
                attrs: { deviceFingerprint: _vm.device_fingerprint },
                on: {
                  "update:deviceFingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                  "update:device-fingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                },
              })
            : _vm._e(),
          _vm.payment_method === "CARD-PAYMENT"
            ? _c("CardPaymentPage", {
                attrs: { deviceFingerprint: _vm.device_fingerprint },
                on: {
                  "update:deviceFingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                  "update:device-fingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                },
              })
            : _vm._e(),
          _vm.payment_method === "BITCOIN"
            ? _c("BitcoinPaymentPage", {
                attrs: { deviceFingerprint: _vm.device_fingerprint },
                on: {
                  "update:deviceFingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                  "update:device-fingerprint": function ($event) {
                    _vm.device_fingerprint = $event
                  },
                },
              })
            : _vm._e(),
          _vm.payment_method && _vm.$store.getters.getShowAlternatives
            ? _c("div", { staticClass: "pt-4 pb-2 text-center" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "bg-gray-200 border-gray-300 px-3 py-2 rounded shadow focus:outline-none",
                    on: { click: _vm.clearPaymentMethod },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-row space-x-1 space-between" },
                      [
                        _c("span", [
                          _c(
                            "svg",
                            {
                              staticClass: "h-4 w-4 text-gray-600",
                              staticStyle: { "margin-top": "1.5px" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 20 20",
                                fill: "currentColor",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v("Change payment method"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.$store.getters.deviceIsMobile
            ? _c("div", { staticClass: "py-2 text-center block lg:hidden" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "bg-gray-200 border-gray-300 px-3 py-2 rounded shadow focus:outline-none",
                    on: { click: _vm.closeModal },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex flex-row space-x-1 space-between" },
                      [
                        _c("span", [
                          _c(
                            "svg",
                            {
                              staticClass: "h-4 w-4 text-gray-600",
                              staticStyle: { "margin-top": "1.5px" },
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 20 20",
                                fill: "currentColor",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                                  "clip-rule": "evenodd",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v("Cancel Payment"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "py-4 flex flex-col copyright" }, [
            _c(
              "a",
              {
                staticClass: "text-xs text-gray-500 flex self-center",
                attrs: { href: "https://intasend.com", target: "_blank" },
              },
              [
                _c("div", [
                  _c(
                    "svg",
                    {
                      staticClass: "h-4 w-4",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 20 20",
                        fill: "currentColor",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          d: "M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z",
                          "clip-rule": "evenodd",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("div", [
                  _vm._v("Secured by "),
                  _c("span", { staticClass: "font-semibold" }, [
                    _vm._v("IntaSend"),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }