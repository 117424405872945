var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("t-card", { staticClass: "h-full flex flex-col justify-center" }, [
    _c(
      "div",
      { staticClass: "my-2 overflow-auto" },
      [
        _c("notification", { ref: "notificationAlert" }),
        _c(
          "div",
          {
            ref: "authScreen",
            staticClass: "flex flex-col w-full justify-between space-y-10",
          },
          [
            _c("div", { staticClass: "flex flex-row justify-center" }, [
              _c("img", { attrs: { src: _vm.cardAuthIllustration } }),
            ]),
            _c("div", { staticClass: "text-center" }, [
              _c("h4", { staticClass: "text-gray-700 text-lg font-semibold" }, [
                _vm._v(" Preparing card authorization "),
              ]),
            ]),
            _c("div", { staticClass: "text-center space-y-2" }, [
              _c("p", { staticClass: "text-indigo-600 font-semibold" }, [
                _vm._v("Please wait"),
              ]),
              _c("p", { staticClass: "text-sm text-gray-500" }, [
                _vm._v("Do not close popup"),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }