import Vue from "vue";
import * as Sentry from "@sentry/vue";
import {
    Integrations
} from "@sentry/tracing";

Sentry.init({
    Vue,
    dsn: "https://69109211db5040218e649a9e2ac0e06a@o397607.ingest.sentry.io/5654278",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
});

Vue.use(Sentry)