<template>
  <t-card class="h-full flex flex-col justify-center">
    <notification />
    <div class="my-2 overflow-auto text-center">
      <div class="flex flex-col w-full justify-between space-y-10">
        <div class="flex flex-row justify-center">
          <img :src="cardInIllustration" />
        </div>
        <div class="text-center">
          <h4 class="text-gray-700 text-lg font-semibold">
            Started card processing
          </h4>
        </div>
        <div class="text-center space-y-2">
          <p class="text-indigo-600 font-semibold">Please wait</p>
          <p class="text-sm text-gray-500">Do not close popup</p>
        </div>
      </div>
      <div v-if="deviceDataCollectionURL">
        <!-- <iframe
          id="deviceIFrame"
          style="
            width: 100px;
            height: 100px;
            border: 0;
            position: absolute;
            top: -5000px;
          "
          @load="deviceCollectionComplete"
          :src="deviceDataCollectionURL"
        ></iframe> -->
        <iframe
          id="cardinal_collection_iframe"
          name="collectionIframe"
          height="1"
          width="1"
          style="display: none"
        ></iframe>
        <form
          ref="cardinalCollectionForm"
          method="POST"
          target="collectionIframe"
          :action="deviceDataCollectionURL"
        >
          <input
            id="cardinal_collection_form_input"
            type="hidden"
            name="JWT"
            :value="accessToken"
          />
        </form>
      </div>
    </div>
  </t-card>
</template>
<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";

import utils from "../utils";
import Notification from "./Notification";
import cardInIllustration from "./../assets/card-in.svg";

let DEVICE_ORIGIN_URL = "https://centinelapistag.cardinalcommerce.com";
if (process.env.NODE_ENV === "production") {
  DEVICE_ORIGIN_URL = "https://centinelapi.cardinalcommerce.com";
}

export default {
  name: "ThreeDSDeviceCollectionPage",
  data() {
    return {
      deviceFound: false,
      sessionId: "",
      accessToken: "",
      polling: false,
      forcePolling: false,
      cardInIllustration: cardInIllustration,
      // mid: "ecomr005134",
      deviceDataCollectionURL: "",
    };
  },
  components: {
    Notification,
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  // computed: {
  //   deviceDataCollectionURL() {
  //     if (this.sessionId) {
  //       return (
  //        DEVICE_ORIGIN_URL + "&session_id=" + this.mid + "" + this.sessionId
  //       );
  //     }
  //     return null;
  //   },
  // },
  mounted() {
    var self = this;
    this.monitorResults();
    window.addEventListener(
      "message",
      function (event) {
        if (event.origin === DEVICE_ORIGIN_URL) {
          // console.log("device response", event.data);
          let resp = JSON.parse(event.data);
          // console.log("device response jsob", resp);
          if (resp.SessionId && resp.Status) {
            self.redirectToCompletePage();
          } else {
            if (!resp.CardinalJWT) {
              let message =
                "We could not complete your request. Please disable scripts blockers or try it in a new device or browser.";
              self.$store.commit("notify", { text: message, type: "error" });
            }
          }
        }
      },
      false
    );
  },
  methods: {
    deviceCollectionComplete() {
      this.deviceFound = true;
      this.$router.push("/3ds-complete/").catch(() => {});
    },
    monitorResults() {
      let self = this;
      this.polling = setInterval(() => {
        if (this.sessionId && this.accessToken) {
          clearInterval(self.polling);
          var cardinalCollectionForm = self.$refs.cardinalCollectionForm;
          if (cardinalCollectionForm) {
            cardinalCollectionForm.submit();
          }
        }
        if (!this.sessionId && !this.accessToken) {
          self.getInitDetails();
        }
      }, 3000);
    },
    redirectToCompletePage() {
      this.$router.push("/3ds-complete/").catch(() => {});
    },
    getInitDetails() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = this.$store.getters.get3DSPayload;
      axios
        .post("payment/collection/3ds-get-status/", payload)
        .then((resp) => {
          loader.hide();
          self.accessToken = resp.data.payer_auth_access_token;
          self.threeDStatus = resp.data.three_d_status;
          self.sessionId = resp.data.payer_auth_reference_id;
          self.deviceDataCollectionURL =
            resp.data.payer_auth_device_collect_url;
          if (self.threeDStatus === "ENROLLMENT" && self.accessToken) {
            self.$store.commit("setCardinalJWTAccessToken", self.accessToken);
          } else if (self.threeDStatus === "FAILED") {
            self.$router
              .push("/results/" + self.$store.getters.get3DSPayload.reference)
              .catch(() => {});
          }
        })
        .catch((err) => {
          loader.hide();
          
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
  },
};
</script>