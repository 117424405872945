var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mpesa-payment" },
    [
      _c(
        "h1",
        { staticClass: "text-xl font-semibold text-gray-700 my-6 text-center" },
        [_vm._v(" Pay with M-Pesa ")]
      ),
      _c("notification"),
      _c(
        "div",
        { staticClass: "flex flex-row mb-4 bg-gray-100 rounded-full" },
        [
          _c(
            "button",
            {
              staticClass:
                "flex-1 bg-gray-100 py-2 px-4 text-center font-semibold text-sm focus:outline-none border-0 focus:bg-gray-300 focus:rounded-full rounded-full",
              class: {
                "bg-gray-300 rounded-full": _vm.mpesaMethod === "STK-PUSH",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleMPesaMethod("STK-PUSH")
                },
              },
            },
            [_vm._v(" Express ")]
          ),
          _c(
            "button",
            {
              staticClass:
                "flex-1 bg-gray-100 py-2 px-4 text-center font-semibold text-sm focus:outline-none border-0 focus:bg-gray-300 focus:rounded-full rounded-full",
              class: {
                "bg-gray-300 rounded-full": _vm.mpesaMethod === "PAYBILL",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleMPesaMethod("PAYBILL")
                },
              },
            },
            [_vm._v(" Use Pay Bill ")]
          ),
        ]
      ),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSubmit.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "mb-2" }, [
            _c("div", [_c("t-label", [_vm._v("Phone")])], 1),
            _c("div", [
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "px-2 py-1 bg-gray-200 border text-sm flex flex-col font-bold text-gray-600 justify-center",
                  },
                  [_vm._v(" 254 ")]
                ),
                _c(
                  "div",
                  { staticClass: "flex-1" },
                  [
                    _c("t-input", {
                      attrs: {
                        type: "text",
                        extraClass: "rounded-l-none",
                        placeholder: "723xxxxxx",
                      },
                      model: {
                        value: _vm.form.phone_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone_number", $$v)
                        },
                        expression: "form.phone_number",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.validation.hasError("form.phone_number")
                ? _c("div", { staticClass: "text-red-600 text-sm" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.validation.firstError("form.phone_number")) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "mb-2" }, [
            _c("div", [_c("t-label", [_vm._v("Amount")])], 1),
            _c(
              "div",
              [
                _c("t-input", {
                  attrs: {
                    type: "number",
                    placeholder: "Amount in " + _vm.form.currency,
                    disabled: _vm.hasDefaultAmount,
                  },
                  model: {
                    value: _vm.form.amount,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "amount", $$v)
                    },
                    expression: "form.amount",
                  },
                }),
                _vm.validation.hasError("form.amount")
                  ? _c("div", { staticClass: "text-red-600 text-sm" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.validation.firstError("form.amount")) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          !_vm.hasDefaultAPIRef
            ? _c("div", { staticClass: "mb-2" }, [
                _c("div", [_c("t-label", [_vm._v("Reference")])], 1),
                _c(
                  "div",
                  [
                    _c("t-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.form.api_ref,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "api_ref", $$v)
                        },
                        expression: "form.api_ref",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.charges > 0
            ? _c(
                "div",
                { staticClass: "mb-2 bg-gray-100 p-2 flex justify-between" },
                [
                  _c("t-label", [_vm._v("Payment fee")]),
                  _c("t-label", [
                    _vm._v(
                      _vm._s(_vm.form.currency) + " " + _vm._s(_vm.charges)
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.mpesaMethod === "STK-PUSH"
            ? _c("div", { staticClass: "p-2 bg-blue-200" }, [
                _vm._v(
                  " You will receive payment request. Please ensure your phone is on and the SIM card has been used in the last 6 months. Enter your PIN to complete. "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pt-2" },
            [
              _vm.mpesaMethod === "STK-PUSH"
                ? _c("t-button", [
                    _vm._v(" Pay "),
                    _vm.form.currency && _vm.total
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.form.currency) + " " + _vm._s(_vm.total)
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.mpesaMethod === "PAYBILL"
                ? _c("t-button", [_vm._v(" Generate Payment Instruction ")])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }