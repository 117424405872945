var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "t-card",
    {},
    [
      _c(
        "h1",
        { staticClass: "text-xl font-semibold text-gray-700 my-6 text-center" },
        [_vm._v("Payment status")]
      ),
      _c("notification"),
      _c(
        "div",
        { staticClass: "my-2 overflow-auto" },
        [
          _vm.result.invoice.provider == "M-PESA"
            ? _c("m-pesa-results-page", {
                attrs: { result: _vm.result },
                on: {
                  "update:result": function ($event) {
                    _vm.result = $event
                  },
                },
              })
            : _vm._e(),
          _vm.result.invoice.provider == "CARD-PAYMENT"
            ? _c("card-results-page", {
                attrs: { result: _vm.result },
                on: {
                  "update:result": function ($event) {
                    _vm.result = $event
                  },
                },
              })
            : _vm._e(),
          _vm.result.invoice.provider == "BITCOIN"
            ? _c("card-results-page", {
                attrs: { result: _vm.result },
                on: {
                  "update:result": function ($event) {
                    _vm.result = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.result.invoice.state != "COMPLETE" &&
      _vm.result.invoice.state != "FAILED"
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "t-light-button",
                { attrs: { variant: "light" }, on: { click: _vm.getResult } },
                [_vm._v("Refresh Status")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.result.invoice.state == "FAILED"
        ? _c("div", { staticClass: "py-2 text-center font-semibold" })
        : _vm._e(),
      _vm.$store.getters.deviceIsMobile
        ? _c("div", { staticClass: "mt-4 py-2 text-center block lg:hidden" }, [
            _c(
              "button",
              {
                staticClass:
                  "bg-gray-200 md:hidden border-gray-300 px-3 py-2 rounded shadow focus:outline-none",
                on: { click: _vm.closeModal },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex flex-row space-x-1 space-between" },
                  [
                    _c("span", [
                      _c(
                        "svg",
                        {
                          staticClass: "h-4 w-4 text-gray-600",
                          staticStyle: { "margin-top": "1.5px" },
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 20 20",
                            fill: "currentColor",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                              "clip-rule": "evenodd",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("span", { staticClass: "text-sm" }, [
                      _vm._v("Close payment"),
                    ]),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }