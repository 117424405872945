import Vue from "vue"

import TButton from "./TButton"
import TLightButton from "./TLightButton"
import TCard from "./TCard"
import TInput from "./TInput"
import TLabel from "./TLabel"

import './../assets/style.css'

Vue.component('t-card', TCard)
Vue.component('t-button', TButton)
Vue.component('t-light-button', TLightButton)
Vue.component('t-label', TLabel)
Vue.component('t-input', TInput)
Vue.component('t-label', TLabel)