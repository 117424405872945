<template>
  <t-card class="h-full flex flex-col justify-center">
    <div class="my-2 overflow-auto">
      <notification ref="notificationAlert" />
      <div
        ref="authScreen"
        class="flex flex-col w-full justify-between space-y-10"
      >
        <div class="flex flex-row justify-center">
          <img :src="cardAuthIllustration" />
        </div>
        <div class="text-center">
          <h4 class="text-gray-700 text-lg font-semibold">
            Preparing card authorization
          </h4>
        </div>
        <div class="text-center space-y-2">
          <p class="text-indigo-600 font-semibold">Please wait</p>
          <p class="text-sm text-gray-500">Do not close popup</p>
        </div>
      </div>
    </div>
  </t-card>
</template>

<script>
``;
import axios from "axios";
import utils from "../utils";
import * as Sentry from "@sentry/vue";
if (process.env.NODE_ENV === "production") {
  require("./songbird-prod");
} else {
  require("./songbird");
}

import cardAuthIllustration from "./../assets/card-in.svg";
import Notification from "./Notification";

export default {
  name: "ThreeDSCompleteSetupPage",
  data() {
    return {
      processing: false,
      threeDStatus: "",
      enrollAcsUrl: "",
      enrollPaReq: "",
      enrollTransactionID: "",
      cardAuthIllustration: cardAuthIllustration,
    };
  },
  components: {
    Notification,
  },
  watch: {
    "$store.getters.getNotifications"(newVal) {
      if (newVal) {
        let notifyEl = this.$refs.notificationAlert;
        notifyEl.scrollTop = notifyEl.scrollHeight;
      }
    },
  },
  mounted() {
    this.checkEnrollment();
    this.setUpCompleteListener();
    this.paymentsValidationLister();
  },
  methods: {
    monitorResults() {
      let self = this;
      this.polling = setInterval(() => {
        self.getStatus();
      }, 3000);
    },
    getStatus() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = this.$store.getters.get3DSPayload;
      axios
        .post("payment/collection/3ds-get-status/", payload)
        .then((resp) => {
          loader.hide();
          self.threeDStatus = resp.data.three_d_status;
          if (self.threeDStatus === "STEP_UP") {
            clearInterval(self.polling);
            self.enrollAcsUrl = resp.data.enroll_acs_url;
            self.enrollPaReq = resp.data.enroll_pa_req;
            self.enrollTransactionID = resp.data.enroll_transaction_id;
            if (self.enrollPaReq && self.enrollAcsUrl) {
              self.setup3DWindow();
            }
          } else if (
            self.threeDStatus === "FAILED" ||
            self.threeDStatus === "COMPLETE"
          ) {
            clearInterval(self.polling);
            self.$router
              .push("/results/" + self.$store.getters.get3DSPayload.reference)
              .catch(() => {});
          }
        })
        .catch((err) => {
          loader.hide();
          console.error(err);
        });
    },
    checkEnrollment() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = this.$store.getters.get3DSPayload;

      axios
        .post("payment/collection/3ds-enrollment-check/", payload)
        .then(() => {
          loader.hide();
          self.monitorResults();
        })
        .catch((err) => {
          loader.hide();
          self.$store.commit("notify", { text: err, type: "error" });
          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
    setup3DWindow() {
      let settings = {
        logging: {
          level: "verbose",
        },
        payment: {
          framework: "inline",
        },
      };
      if (process.env.NODE_ENV === "production") {
        settings = {
          logging: {
            level: "off",
          },
          payment: {
            framework: "inline",
          },
        };
      }
      window.Cardinal.configure(settings);

      window.Cardinal.setup("init", {
        jwt: this.$store.getters.getCardinalJWTAccessToken,
      });
    },
    setUpCompleteListener() {
      var self = this;
      window.Cardinal.on("payments.setupComplete", function () {
        self.inlineSetupListener();

        window.Cardinal.continue(
          "cca",
          {
            AcsUrl: self.enrollAcsUrl,
            Payload: self.enrollPaReq,
          },
          {
            OrderDetails: {
              TransactionId: self.enrollTransactionID,
            },
          }
        );
      });
    },
    paymentsValidationLister() {
      var self = this;
      window.Cardinal.on("payments.validated", function (data) {
        console.log(data)
        if (data.ErrorNumber === 0 && data.ErrorDescription === "Success") {
          if (data.Payment.Type === "CCA") {
            self.processing = false;
            if (
              self.enrollTransactionID === data.Payment.ProcessorTransactionId
            ) {
              self.validateAndCheckout();
            }
          } else {
            self.processing = false;
            self.$store.commit("notify", {
              text: "We could not complete your authorization request. Please check the card details and ensure you have provided the right information and try again. If the problem persists, feel free to contact support for assistance.",
              type: "error",
            });
            Sentry.captureException({
              resp: data,
              channel: "CARD-PAYMENT",
            });
          }
        } 
        else {
          self.$store.commit("notify", {
            text: "We could not complete your authorization request. Please check the card details and ensure you have provided the right information and try again. If the problem persists, feel free to contact support for assistance.",
            type: "error",
          });

          Sentry.captureException({
            resp: data,
            channel: "CARD-PAYMENT",
          });
          self.processing = false;
        }
      });
    },
    inlineSetupListener() {
      var self = this;
      window.Cardinal.on(
        "ui.inline.setup",
        function (htmlTemplate, details, resolve, reject) {
          self.$store.commit("notify", {
            text: "Please authenticate your request. Popup will automatically close on complete.",
            type: "info",
          });
          try {
            var container = self.$refs.authScreen; // The element we will inject the HTML template into
            if (htmlTemplate !== undefined && details !== undefined) {
              // Depending on your integration you may need to account for other items when processing different payment types
              switch (details.paymentType) {
                case "CCA":
                  // Process CCA authentication screen
                  switch (details.data.mode) {
                    case "static":
                      // Inject Iframe into DOM in visible spot
                      container = self.$refs.authScreen;
                      break;

                    case "suppress":
                      // Inject Iframe into DOM out of view
                      container = self.$refs.authScreen;
                      break;
                    default:
                      throw new Error(
                        "Unsupported inline mode found [" +
                          details.data.mode +
                          "]"
                      );
                  }

                  break;
                default:
                  throw new Error(
                    "Unsupported inline payment type found [" +
                      details.paymentType +
                      "]"
                  );
              }
              // Because the template we get from Songbird is a string template, we need to inject it as innerHTML
              container.innerHTML = htmlTemplate;
              // Inform Songbird that we have successfully injected the iframe into the DOM and the transaction can proceed forward
              resolve();
            } else {
              throw new Error(
                "Unable to process request due to invalid arguments"
              );
            }
          } catch (error) {
            // An error occurred, we need to inform Songbird that an error occurred so Songbird can abondon the transaction and trigger the 'payments.validated' event with an error
            self.$store.commit("notify", {
              text: "Problem experienced while preparing your authentication. Please contact support at suppot@intasend.com",
              type: "error",
            });
            Sentry.captureException({
              resp: error,
              channel: "CARD-PAYMENT",
            });
            reject(error);
          }
        }
      );
    },
    validateAndCheckout() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = this.$store.getters.get3DSPayload;
      axios
        .post("/payment/collection/3ds-validation-checkout/", payload)
        .then(() => {
          loader.hide();
          self.$router.push(
            "/results/" + self.$store.getters.get3DSPayload.reference
          );
        })
        .catch((err) => {
          loader.hide();
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
  },
};
</script>

<style>
#Cardinal-CCA-IFrame {
  width: 100% !important;
}
</style>
