import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    publicKey: "",
    notifications: [],
    pluginHost: "",
    isMobile: false,
    showAlternatives: true,
    defaultItems: {},
    threeDSPayload: {
      signature: null,
      referrence: null
    },
    cardinalJWTAccessToken: "",
    checkoutKeys: {
      signature: null,
      checkoutID: null
    },
    bitcoinPayload: {
      signature: null,
      referrence: null
    },
    invoice: {},
    payInfo: ""
  },
  getters: {
    getNotifications(state) {
      return state.notifications
    },
    getPublicKey(state) {
      return state.publicKey
    },
    getPluginHost(state) {
      return state.pluginHost
    },
    deviceIsMobile(state) {
      return state.isMobile
    },
    getShowAlternatives(state) {
      return state.showAlternatives
    },
    getDefaultItems(state) {
      return state.defaultItems
    },
    get3DSPayload(state) {
      return state.threeDSPayload
    },
    getBitcoinPayload(state) {
      return state.bitcoinPayload
    },
    getCardinalJWTAccessToken(state) {
      return state.cardinalJWTAccessToken
    },
    getCheckoutKeys(state) {
      return state.checkoutKeys
    },
    getInvoice(state) {
      return state.invoice
    },
    getPayInfo(state) {
      return state.payInfo
    }
  },
  mutations: {
    notify(state, message) {
      state.notifications = [message]
    },
    clearNotification(state) {
      state.notifications = []
    },
    setPublicKey(state, publicKey) {
      state.publicKey = publicKey
    },
    setPluginHost(state, pluginHost) {
      state.pluginHost = pluginHost
    },
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    setShowAlternatives(state, showAlternatives) {
      state.showAlternatives = showAlternatives
    },
    setDefaultItems(state, defaultItems) {
      state.defaultItems = defaultItems
    },
    set3DSPayload(state, payload) {
      state.threeDSPayload = payload
    },
    setBitcoinPayload(state, payload) {
      state.bitcoinPayload = payload
    },
    setCheckoutKeys(state, payload) {
      state.checkoutKeys = payload
    },
    setCardinalJWTAccessToken(state, cardinalJWTAccessToken) {
      state.cardinalJWTAccessToken = cardinalJWTAccessToken
    },
    setInvoice(state, invoiceResp){
      state.invoice = invoiceResp
    },
    setPayInfo(state, payInfo) {
      state.payInfo = payInfo
    }
  },
  actions: {},
  modules: {}
})