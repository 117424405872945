<template>
  <input
    :type="type"
    ref="inputField"
    @input="updateValue()"
    :placeholder="placeholder"
    class="py-2 px-3 w-full block focus:outline-none border rounded" 
    :class="extraClass"
  />
</template>

<script>
export default {
  name: "TInput",
  props: {
    type: {
      required: true
    },
    placeholder: {
      required: false
    },
    extraClass: {
      required: false
    }
  },
  methods: {
    updateValue() {
      this.$emit("input", this.$refs.inputField.value);
    }
  }
};
</script>