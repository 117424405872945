var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      !_vm.link
        ? _c(
            "button",
            {
              staticClass:
                "px-4 py-2 font-bold rounded shadow flex justify-between focus:outline-none",
              class: _vm.btnClass,
              attrs: {
                type: _vm.type ? _vm.type : "submit",
                disabled: _vm.disabled,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [_c("span", { staticClass: "m-auto" }, [_vm._t("default")], 2)]
          )
        : _vm._e(),
      _vm.link
        ? _c(
            "router-link",
            {
              staticClass:
                "px-4 py-2 font-bold rounded shadow flex justify-between focus:outline-none",
              class: _vm.btnClass,
              attrs: { to: _vm.link },
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }