<template>
  <div class="choose-payment">
    <h1 class="text-xl font-semibold text-gray-700 mb-6 text-center">
      How would you like to pay?
    </h1>
    <div class="space-y-2">
      <div
        v-for="method in getMethods"
        :key="method.id"
        class="
          p-4
          bg-gray-300
          hover:bg-gray-200
          border-b border-gray-200
          cursor-pointer
          rounded
          flex flex-row
          justify-between
        "
        @click="selectMethod(method.value)"
        :class="{ 'bg-blue-600 text-blue-100': method.value === paymentMethod }"
      >
        <div class="flex flex-col justify-center">
          <t-label
            :uppercase="false"
            class="text-gray-700 text-base cursor-pointer"
          >
            {{ method.label }}
          </t-label>
        </div>
        <div class="flex">
          <div class="flex flex-row justify-center">
            <img
              :src="method.image"
              :class="{
                'h-8': method.value === 'M-PESA',
                'w-* h-8': method.value === 'CARD-PAYMENT',
                'w-22 h-8': method.value === 'BITCOIN'
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mpesaLogo from "./../assets/mpesa.png";
import cardLogo from "./../assets/cards.png";
import bitcoinLogo from "./../assets/bitcoin.png";
import cashAppLogo from "./../assets/cashapp.png";


export default {
  name: "ChoosePayment",
  props: {
    paymentMethod: {
      type: String,
      required: true,
    },
    paymentDefaultSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      methods: [
        {
          id: "M-PESA",
          value: "M-PESA",
          label: "Pay with M-Pesa",
          image: mpesaLogo,
        },
        {
          id: "CARD-PAYMENT",
          value: "CARD-PAYMENT",
          label: "Pay with Card",
          image: cardLogo,
        },
        {
          id: "BITCOIN",
          value: "BITCOIN",
          label: "Pay with Bitcoin",
          image: bitcoinLogo
        },
        {
          id: "CASHAPP-BTC",
          value: "BITCOIN",
          label: "Pay with Cash App (BTC)",
          image: cashAppLogo
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      defaultItems: "getDefaultItems",
    }),
    getMethods() {
      let prefferredMethods = [];
      if (["EUR", "GBP"].includes(this.defaultCurrency)) {
        // if (this.paymentDefaultSettings.enable_card_payment) {
          return [
            {
              id: "CARD-PAYMENT",
              value: "CARD-PAYMENT",
              label: "Pay with card",
              image: cardLogo,
            },
          ];
        // }
      }
      if (["USD"].includes(this.defaultCurrency)) {
        // if (this.paymentDefaultSettings.enable_card_payment) {
          prefferredMethods.push({
            id: "CARD-PAYMENT",
            value: "CARD-PAYMENT",
            label: "Pay with card",
            image: cardLogo,
          });
        // }
        if (this.paymentDefaultSettings.enable_bitcoin_payment) {
          prefferredMethods.push({
            id: "BITCOIN",
            value: "BITCOIN",
            label: "Pay with Bitcoin",
            image: bitcoinLogo,
          });
          prefferredMethods.push({
            id: "CASHAPP-BTC",
            value: "BITCOIN",
            label: "Pay with Cash App (BTC)",
            image: cashAppLogo,
          });
        }
        return prefferredMethods;
      }
      if (["KES"].includes(this.defaultCurrency)) {
        // if (this.paymentDefaultSettings.enable_mpesa_payment) {
          prefferredMethods.push({
            id: "M-PESA",
            value: "M-PESA",
            label: "Pay with M-Pesa",
            image: mpesaLogo,
          });
        // }
        // if (this.paymentDefaultSettings.enable_card_payment) {
          prefferredMethods.push({
            id: "CARD-PAYMENT",
            value: "CARD-PAYMENT",
            label: "Pay with card",
            image: cardLogo,
          });
        // }
        return prefferredMethods;
      }
      return this.methods;
    },
    defaultCurrency: {
      get() {
        if (!this.defaultItems) return false;
        return this.defaultItems.currency;
      },
    },
  },
  methods: {
    selectMethod(method) {
      this.$emit("update:paymentMethod", method);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
