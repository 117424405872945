function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const handleHTTPError = function (error) {
    if (error.response) {
      if (!error.response.data) {
        return error.response;
      }
  
      const resp = error.response.data;
      if (resp.detail || resp.details) {
        return capitalizeFirstLetter(resp.detail || resp.details);
      }
      if (resp.type && resp.errors) {
        if (resp.errors.length > 0) {
          return capitalizeFirstLetter(resp.errors[0].detail.replace("_", " "))
        }
      }
      return resp
    }
    return error;
};
  
export default { handleHTTPError };
  