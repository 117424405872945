<template>
  <div class="bg-white">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">
      Pay with card
    </h1>
    <notification ref="notificationAlert" />
    <form @submit.prevent="onSubmit">
      <div class="mb-3" v-if="!hasDefaultEmail">
        <div>
          <t-label>Email</t-label>
        </div>
        <div>
          <t-input
            type="email"
            v-model="form.email"
            placeholder="Enter your email"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.email')"
          >
            {{ validation.firstError("form.email") }}
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="!hasDefaultFirstName || !hasDefaultLastName">
        <div>
          <t-label>Name on Card</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Enter your name"
            v-model="fullName"
            name = "Name"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('fullName')"
          >
            {{ validation.firstError("fullName") }}
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div>
          <t-label>Card Number</t-label>
        </div>
        <div>
          <t-input
            type="text"
            v-model="form.card_number"
            placeholder="Card number"
            v-mask="'#### #### #### ####'"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.card_number')"
          >
            {{ validation.firstError("form.card_number") }}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div>
          <div class="flex flex-row space-x-2">
            <div>
              <div>
                <t-label>Expiry</t-label>
              </div>
              <div>
                <t-input
                  type="text"
                  v-model="form.expiry"
                  v-mask="'##/##'"
                  placeholder="MM/YY"
                />
                <div
                  class="text-red-600 text-sm"
                  v-if="validation.hasError('form.expiry')"
                >
                  {{ validation.firstError("form.expiry") }}
                </div>
              </div>
            </div>
            <div>
              <div>
                <t-label>CVV</t-label>
              </div>
              <div>
                <t-input
                  type="number"
                  v-model="form.cvc"
                  placeholder="CVC"
                  v-mask="'####'"
                />
                <div
                  class="text-red-600 text-sm"
                  v-if="validation.hasError('form.cvc')"
                >
                  {{ validation.firstError("form.cvc") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="mb-3">
        <div>
          <t-label>Country</t-label>
        </div>
        <div>
          <div class="relative">
            <select
              v-model="form.country"
              class="
                block
                appearance-none
                w-full
                bg-white
                text-gray-700
                py-2
                px-3
                pr-8
                leading-tight
                focus:outline-none
                focus:bg-white
                border
                rounded
              "
            >
              <option value="">Select country</option>
              <option
                v-for="country in countries"
                :key="country.code"
                :value="country.code"
              >
                {{ country.name }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.country')"
          >
            {{ validation.firstError("form.country") }}
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="showZipCode || showFullAddress">
        <div>
          <t-label>ZIP</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Enter zip code"
            v-model="form.zipcode"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.zipcode')"
          >
            {{ validation.firstError("form.zipcode") }}
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="showState || showFullAddress">
        <div>
          <t-label>State</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Enter state"
            v-model="form.state"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.state')"
          >
            {{ validation.firstError("form.state") }}
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="showFullAddress">
        <div>
          <t-label>City</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Enter city"
            v-model="form.city"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.city')"
          >
            {{ validation.firstError("form.city") }}
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="showFullAddress || showAddress">
        <div>
          <t-label>Street Address</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Enter street address"
            v-model="form.address"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.address')"
          >
            {{ validation.firstError("form.address") }}
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="!hasDefaultCurrency">
        <div>
          <t-label>Currency</t-label>
        </div>
        <div>
          <div class="relative">
            <select
              v-model="form.currency"
              class="
                block
                appearance-none
                w-full
                bg-white
                text-gray-700
                py-2
                px-3
                pr-8
                leading-tight
                focus:outline-none
                focus:bg-white
                border
                rounded
              "
            >
              <option v-for="(currency, $index) in currencies" :key="$index">
                {{ currency }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.currency')"
          >
            {{ validation.firstError("form.currency") }}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div>
          <t-label>Amount</t-label>
        </div>
        <div>
          <t-input
            type="number"
            :placeholder="form.currency"
            v-model="form.amount"
            :disabled="hasDefaultAmount" 
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.amount')"
          >
            {{ validation.firstError("form.amount") }}
          </div>
        </div>
      </div>
      <div class="mb-3" v-if="!hasDefaultAPIRef">
        <div>
          <t-label>Reference</t-label>
        </div>
        <div>
          <t-input
            type="text"
            placeholder="Optional reference number"
            v-model="form.api_ref"
          />
        </div>
      </div>
      <div class="mb-3 bg-gray-100 p-2 flex justify-between" v-if="charges > 0">
        <t-label>Payment fee</t-label>
        <t-label>{{ form.currency }} {{ charges }}</t-label>
      </div>
      <div class="pt-2">
        <t-button type="submit" :disabled.sync="processing">
          <span v-if="!processing">
            Pay
            <span v-if="form.currency && total"
              >{{ form.currency }} {{ total }}</span
            >
          </span>
          <span v-if="processing"> Processing ..</span>
        </t-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import * as Sentry from "@sentry/vue";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;

const countries = require("./../plugins/countries");
import utils from "../utils";
import Notification from "./Notification";

export default {
  name: "CardPaymentPage",
  data() {
    return {
      defaultItems: {},
      processing: false,
      form: {
        method: "CARD-PAYMENT",
        email: "",
        currency: "USD",
        card_number: "",
        expiry: "",
        cvc: "",
        api_ref: "",
        amount: 0,
        country: "",
        state: "",
        city: "",
        zipcode: "",
        cca_transaction_id: "",
      },
      currencies: ["USD", "EUR", "GBP", "KES"],
      countries: countries,
      total: 0,
      authenticationData: {},
    };
  },
  props: {
    deviceFingerprint: {
      required: false,
    },
  },
  computed: {
    showZipCode() {
      if (!this.form.country) return false;
      return ["US", "GB", "CA"].includes(this.form.country);
    },
    showAddress(){
      if (!this.form.country) return false;
      return ["US", "GB", "CA"].includes(this.form.country);
    },
    showState() {
      if (!this.form.country) return false;
      return ["US", "GB", "CA"].includes(this.form.country);
    },
    showFullAddress() {
      let invoice = this.$store.getters.getInvoice
      if (!invoice) {
        return false
      }
      if (invoice.failed_code) {
        if (invoice.failed_code.toString() === 'MISSING_FIELD' || invoice.failed_code.toString() === 'INVALID_DATA' ) {
          return true
        }
      }
      return false
    },
    fullName: {
      set(value){
        if (value) {
          const [first_name, last_name] = value.split(' ')
          this.form.first_name = first_name
          this.form.last_name = last_name
        }
      },
      get() {
        if (!this.form.first_name || !this.form.last_name) {
          return ""
        }
        return this.form.first_name.concat(" ", this.form.last_name)
      }
    },
    hasDefaultFirstName() {
      if (!this.defaultItems) return false;
      return this.defaultItems.first_name;
    },
    hasDefaultLastName() {
      if (!this.defaultItems) return false;
      return this.defaultItems.last_name;
    },
    hasDefaultAmount() {
      if (!this.defaultItems) return false;
      if (
        this.defaultItems.amount === "" ||
        this.defaultItems.amount === undefined
      ) {
        return false;
      }
      if (this.defaultItems.amount) {
        if (parseInt(this.defaultItems.amount) === 0) {
          return false;
        }
      }
      return this.defaultItems.amount;
    },
    hasDefaultCurrency() {
      if (!this.defaultItems) return false;
      return this.defaultItems.currency;
    },
    hasDefaultEmail() {
      if (!this.defaultItems) return false;
      return this.defaultItems.email;
    },
    hasDefaultAPIRef() {
      if (!this.defaultItems) return false;
      return this.defaultItems.api_ref;
    },
    card_number_cleaned() {
      if (this.form.card_number) {
        return parseInt(this.form.card_number.toString().replace(/\s/g, ""));
      }
      return this.form.card_number
    },
    charges() {
      return Math.round((this.total - this.form.amount) * 100) / 100;
    },
  },
  components: {
    Notification,
  },
  validators: {
    "form.email": function (value) {
      return Validator.value(value).required().email();
    },
    "form.country": function (value) {
      return Validator.value(value).required();
    },
    "form.zipcode": function (value) {
      if (["US", "GB", "CA"].includes(this.form.country)) {
        return Validator.value(value).required();
      }
    },
    "form.address": function (value) {
      if (["US", "GB", "CA"].includes(this.form.country)) {
        return Validator.value(value).required();
      }
      if(this.showFullAddress){
        return Validator.value(value).required();
      }
    },
    "form.state": function (value) {
      if (this.form.country === "GB") {
        return Validator.value(value).required();
      }
      if(this.showState || this.showFullAddress){
        return Validator.value(value).required();
      }
    },
    "form.city": function (value) {
      if(this.showFullAddress){
        return Validator.value(value).required();
      }
    },
    "fullName": function (value) {
      if (!this.form.first_name && !this.form.last_name ) {
        return Validator.value(value).required();
      }
    },
    "form.card_number": function (value) {
      return Validator.value(value).required();
    },
    "form.expiry": function (value) {
      return Validator.value(value).required();
    },
    "form.cvc": function (value) {
      return Validator.value(value).required().integer();
    },
    "form.currency": function (value) {
      return Validator.value(value).required();
    },
    "form.amount": function (value) {
      if (
        this.form.currency == "USD" ||
        this.form.currency == "EUR" ||
        this.form.currency == "GBP"
      ) {
        return Validator.value(value).required().float().between(0.1, 5000);
      } else {
        return Validator.value(value).required().float().between(10, 500000);
      }
    },
  },
  watch: {
    "form.amount"(newVal) {
      if (newVal) {
        this.getTotal();
      }
    },
    "$store.getters.getNotifications"(newVal) {
      if (newVal) {
        let notifyEl = this.$refs.notificationAlert;
        notifyEl.scrollTop = notifyEl.scrollHeight;
      }
    },
  },
  mounted() {
    this.defaultItems = this.$store.getters.getDefaultItems;
    this.form.phone_number = this.defaultItems.phone_number || "";
    this.form.email = this.defaultItems.email || "";
    this.form.amount = this.defaultItems.amount || "";
    this.form.currency = this.defaultItems.currency || "USD";
    this.form.country = this.defaultItems.country || "";
    this.form.zipcode = this.defaultItems.zipcode || "";
    this.form.address = this.defaultItems.address || "";
    this.form.first_name = this.defaultItems.first_name || "";
    this.form.last_name = this.defaultItems.last_name || "";
    this.form.comment = this.defaultItems.comment || "";
    this.form.api_ref = this.defaultItems.api_ref || "";
    this.form.callback_url = this.defaultItems.callback_url || "";
    this.form.card_tarrif = this.defaultItems.card_tarrif || "BUSINESS-PAYS";
    this.form.host = this.defaultItems.host;
    this.form.wallet_id = this.defaultItems.wallet_id || "";

    this.form.card_number = this.defaultItems.card_number || "";
    this.form.expiry = this.defaultItems.expiry || "";
    this.form.cvc = this.defaultItems.cvc || "";

    // Show error of previous invoice
    let invoice = this.$store.getters.getInvoice
    if (invoice) {
      if (invoice.failed_reason){
        this.$store.commit("notify", { text: invoice.failed_reason, type: "error" });
      }
    }
  },
  methods: {
    loadAuthDetails(payload) {
      let checkoutKeys = this.$store.getters.getCheckoutKeys;
      if (checkoutKeys.signature && checkoutKeys.checkoutID) {
        payload.signature = checkoutKeys.signature;
        payload.checkout_id = checkoutKeys.checkoutID;
      } else {
        payload.public_key = this.$store.getters.getPublicKey;
      }
      return payload;
    },
    onSubmit(){
      let self = this;
      this.$validate().then(function (success) {
        if (success) {
          self.sendRequest()
        }
      })
    },
    sendRequest() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      this.form = this.loadAuthDetails(this.form);
      this.form.device_fingerprint = this.deviceFingerprint;

      let payload = this.form;
      payload.card_number = this.card_number_cleaned;

      this.$store.commit("setPayInfo", payload)
      
      axios
        .post("payment/collection/", payload)
        .then((resp) => {
          loader.hide();
          self.$store.commit("set3DSPayload", {
            signature: resp.data.signature,
            reference: resp.data.invoice.invoice_id,
          });
          self.$router.push("/3ds-device-collection/");
        })
        .catch((err) => {
          loader.hide();
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });
        });
    },
    getTotal() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = {
        tarrif: this.form.card_tarrif,
        method: "CARD-PAYMENT",
        currency: this.form.currency,
        amount: this.form.amount,
        public_key: this.$store.getters.getPublicKey,
      };
      axios
        .post("payment/get_amount_estimate/", payload)
        .then((resp) => {
          loader.hide();
          self.total = resp.data.total;
        })
        .catch((err) => {
          loader.hide();
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });
          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
  },
};
</script>