<template>
  <div class="min:h-full bg-white border-gray-300 hover:bg-light-100">
    <div class="px-0 lg:px-1 pt-2 flex justify-between" v-if="title">
      <h4 class="text-xl">{{title}}</h4>
      <slot name="actions"></slot>
    </div>
    <div class="px-2 py-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "TCard",
  props: {
    title: {
      required: false
    }
  }
};
</script>