import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'

import './plugins/validation'
import './plugins/loader'
import './plugins/mask'
import './ui'
import './plugins/sentry'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')