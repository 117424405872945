var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "h-screen overflow-auto flex rounded",
      attrs: { id: "app" },
    },
    [
      _c("div", { staticClass: "w-full lg:w-1/3 lg:m-auto" }, [
        _c(
          "div",
          { staticClass: "bg-gray-200 lg:p-3 p-2 rounded" },
          [_c("router-view", { staticClass: "rounded" })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }