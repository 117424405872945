var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass:
        "text-xs tracking-wide pr-4 font-semibold block text-gray-700 mb-1",
      class: { uppercase: _vm.uppercase },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }