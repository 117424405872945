var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.notifications.length > 0
    ? _c(
        "div",
        { staticClass: "my-2" },
        _vm._l(_vm.notifications, function (notification, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "px-4 py-2 text-gray-100 rounded",
              class: {
                "bg-red-500 border-red-500": notification.type == "error",
                "bg-indigo-600 border-indigo-600": notification.type == "info",
              },
            },
            [_vm._v(_vm._s(notification.text))]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }