var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "t-card",
    { staticClass: "h-full flex flex-col justify-center" },
    [
      _c(
        "h1",
        { staticClass: "text-xl font-semibold text-gray-700 my-6 text-center" },
        [_vm._v(" Pay with Bitcoin ")]
      ),
      _c("notification"),
      _c("div", { staticClass: "my-2 overflow-auto text-center" }, [
        _c(
          "div",
          { staticClass: "flex flex-col w-full justify-between space-y-10" },
          [
            _c("div", { staticClass: "flex flex-col space-x-6" }, [
              _c(
                "div",
                { staticClass: "flex flex-row mb-4 bg-gray-100 rounded-full" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "flex-1 bg-gray-100 py-2 px-4 text-center font-semibold text-sm focus:outline-none border-0 focus:bg-gray-300 focus:rounded-full rounded-full",
                      class: {
                        "bg-gray-300 rounded-full": _vm.chain === "MAIN",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.activateChain("MAIN")
                        },
                      },
                    },
                    [_vm._v(" Main Chain ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "flex-1 bg-gray-100 py-2 px-4 text-center font-semibold text-sm focus:outline-none border-0 focus:bg-gray-300 focus:rounded-full rounded-full",
                      class: {
                        "bg-gray-300 rounded-full": _vm.chain === "LIGHTNING",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.activateChain("LIGHTNING")
                        },
                      },
                    },
                    [_vm._v(" Lightning ")]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "flex flex-col justify-center text-center" },
                [
                  _vm.amount === 0 && !_vm.service_id
                    ? _c("div", { staticClass: "text-center space-y-2 my-6" }, [
                        _c(
                          "p",
                          { staticClass: "text-indigo-600 font-semibold" },
                          [_vm._v("Please wait")]
                        ),
                        _c("p", { staticClass: "text-sm text-gray-500" }, [
                          _vm._v("Do not close popup"),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.chain === "MAIN" && _vm.amount > 0
                    ? _c("div", { staticClass: "flex flex-col space-y-4" }, [
                        _c("h3", { staticClass: "text-xl" }, [
                          _vm._v(
                            "Pay " +
                              _vm._s(_vm._f("stashToBtc")(_vm.amount)) +
                              " BTC"
                          ),
                        ]),
                        _c("h5", { staticClass: "text-sm text-gray-500" }, [
                          _vm._v("~= " + _vm._s(_vm.fiat_value) + " USD"),
                        ]),
                        _c("h4", { staticClass: "text-lg text-gray-600" }, [
                          _vm._v(
                            " Payment for USD " + _vm._s(_vm.fiat_value) + " "
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn-gradient shadow text-white leading-tight text-center text-sm uppercase rounded focus:outline-none px-4 py-3 font-bold bg-indigo-500",
                              attrs: { href: _vm.btcUri, target: "_blank" },
                            },
                            [_vm._v(" Open in Wallet ")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _vm.address
                              ? _c("QRCanvas", {
                                  staticClass: "h-56",
                                  attrs: { options: _vm.mainChainQROptions },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-sm uppercase w-full overflow-auto",
                          },
                          [_vm._v("Address: " + _vm._s(_vm.address))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.chain === "LIGHTNING" && _vm.amount > 0
                    ? _c("div", { staticClass: "flex flex-col space-y-4" }, [
                        _c("h3", { staticClass: "text-xl" }, [
                          _vm._v(
                            "Pay " +
                              _vm._s(_vm._f("stashToBtc")(_vm.amount)) +
                              " BTC"
                          ),
                        ]),
                        _c("h5", { staticClass: "text-sm text-gray-500" }, [
                          _vm._v("~= " + _vm._s(_vm.fiat_value) + " USD"),
                        ]),
                        _c("h4", { staticClass: "text-lg text-gray-600" }, [
                          _vm._v(
                            " Payment for USD " + _vm._s(_vm.fiat_value) + " "
                          ),
                        ]),
                        _c("div", [
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn-gradient shadow text-white leading-tight text-center text-sm uppercase rounded focus:outline-none px-4 py-3 font-bold bg-indigo-500",
                              attrs: {
                                href: "lightning:" + _vm.lnd_payreq,
                                target: "_blank",
                              },
                            },
                            [_vm._v(" Open in Wallet ")]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex justify-center" },
                          [
                            _vm.lnd_payreq
                              ? _c("QRCanvas", {
                                  staticClass: "h-56",
                                  attrs: { options: _vm.lndChainQROptions },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-sm uppercase w-full overflow-auto",
                          },
                          [_vm._v("PayReq: " + _vm._s(_vm.lnd_payreq))]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }