<template>
  <div class="mpesa-payment">
    <h1 class="text-xl font-semibold text-gray-700 my-6 text-center">
      Pay with M-Pesa
    </h1>
    <notification />
    <div class="flex flex-row mb-4 bg-gray-100 rounded-full">
      <button
        class="
          flex-1
          bg-gray-100
          py-2
          px-4
          text-center
          font-semibold
          text-sm
          focus:outline-none
          border-0
          focus:bg-gray-300 focus:rounded-full
          rounded-full
        "
        :class="{ 'bg-gray-300 rounded-full': mpesaMethod === 'STK-PUSH' }"
        @click="toggleMPesaMethod('STK-PUSH')"
      >
        Express
      </button>
      <button
        class="
          flex-1
          bg-gray-100
          py-2
          px-4
          text-center
          font-semibold
          text-sm
          focus:outline-none
          border-0
          focus:bg-gray-300 focus:rounded-full
          rounded-full
        "
        :class="{ 'bg-gray-300 rounded-full': mpesaMethod === 'PAYBILL' }"
        @click="toggleMPesaMethod('PAYBILL')"
      >
        Use Pay Bill
      </button>
    </div>
    <form @submit.prevent="onSubmit">
      <div class="mb-2">
        <div>
          <t-label>Phone</t-label>
        </div>
        <div>
          <div class="flex">
            <div
              class="
                px-2
                py-1
                bg-gray-200
                border
                text-sm
                flex flex-col
                font-bold
                text-gray-600
                justify-center
              "
            >
              254
            </div>
            <div class="flex-1">
              <t-input
                type="text"
                v-model="form.phone_number"
                extraClass="rounded-l-none"
                placeholder="723xxxxxx"
              />
            </div>
          </div>
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.phone_number')"
          >
            {{ validation.firstError("form.phone_number") }}
          </div>
        </div>
      </div>
      <div class="mb-2">
        <div>
          <t-label>Amount</t-label>
        </div>
        <div>
          <t-input
            type="number"
            :placeholder="'Amount in ' + form.currency"
            v-model="form.amount"
            :disabled="hasDefaultAmount"
          />
          <div
            class="text-red-600 text-sm"
            v-if="validation.hasError('form.amount')"
          >
            {{ validation.firstError("form.amount") }}
          </div>
        </div>
      </div>
      <div class="mb-2" v-if="!hasDefaultAPIRef">
        <div>
          <t-label>Reference</t-label>
        </div>
        <div>
          <t-input type="text" v-model="form.api_ref" />
        </div>
      </div>
      <div class="mb-2 bg-gray-100 p-2 flex justify-between" v-if="charges > 0">
        <t-label>Payment fee</t-label>
        <t-label>{{ form.currency }} {{ charges }}</t-label>
      </div>
      <div class="p-2 bg-blue-200" v-if="mpesaMethod === 'STK-PUSH'">
        You will receive payment request. Please ensure your phone is on and the
        SIM card has been used in the last 6 months. Enter your PIN to complete.
      </div>
      <div class="pt-2">
        <t-button v-if="mpesaMethod === 'STK-PUSH'">
          Pay
          <span v-if="form.currency && total"
            >{{ form.currency }} {{ total }}</span
          >
        </t-button>
        <t-button v-if="mpesaMethod === 'PAYBILL'">
          Generate Payment Instruction
        </t-button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import SimpleVueValidation from "simple-vue-validator";
import * as Sentry from "@sentry/vue";
const Validator = SimpleVueValidation.Validator;

import Notification from "./Notification";
import utils from "../utils";

export default {
  name: "MPesaPaymentPage",
  data() {
    return {
      defaultItems: {},
      mpesaMethod: "STK-PUSH",
      form: {
        method: "M-PESA",
        phone_number: "",
        api_ref: "",
        amount: 0,
        name: "",
        email: "",
        currency: "",
      },
      total: 0,
    };
  },
  props: {
    deviceFingerprint: {
      required: false,
    },
  },
  components: {
    Notification,
  },
  computed: {
    hasDefaultAmount() {
      if (!this.defaultItems) return false;
      if (
        this.defaultItems.amount === "" ||
        this.defaultItems.amount === undefined
      ) {
        return false;
      }
      if (this.defaultItems.amount) {
        if (parseInt(this.defaultItems.amount) === 0) {
          return false;
        }
      }
      return this.defaultItems.amount;
    },
    hasDefaultAPIRef() {
      if (!this.defaultItems) return false;
      return this.defaultItems.api_ref;
    },
    phoneNumber() {
      return "254" + this.form.phone_number;
    },
    charges() {
      return Math.round((this.total - this.form.amount) * 100) / 100;
    },
  },
  validators: {
    "form.phone_number": function (value) {
      return Validator.value(value)
        .required()
        .regex(
          "^[0-9][0-9]{8}$",
          "Enter a valid number with format e.g 72xxxxxxx"
        );
    },
    "form.amount": function (value) {
      return Validator.value(value).required().float().between(10, 150000);
    },
  },
  mounted() {
    this.defaultItems = this.$store.getters.getDefaultItems;
    if (this.defaultItems.phone_number) {
      if (this.defaultItems.phone_number.startsWith(254)) {
        this.form.phone_number =
          this.defaultItems.phone_number.substring(3) || "";
      }
    }
    this.form.amount = this.defaultItems.amount || "";
    this.form.api_ref = this.defaultItems.api_ref || "";
    this.form.email = this.defaultItems.email || "";
    this.form.first_name = this.defaultItems.first_name || "";
    this.form.last_name = this.defaultItems.last_name || "";
    this.form.comment = this.defaultItems.comment || "";
    this.form.callback_url = this.defaultItems.callback_url || "";
    this.form.currency = this.defaultItems.currency || "KES";
    this.form.mobile_tarrif =
      this.defaultItems.mobile_tarrif || "BUSINESS-PAYS";
    this.form.host = this.defaultItems.host;
    this.form.wallet_id = this.defaultItems.wallet_id || "";

    let invoice = this.$store.getters.getInvoice
    if (invoice) {
      if (invoice.state === 'RETRY'){
        this.$store.commit("notify", { text: "We could not initiate the M-Pesa STK Push to your number. Please use the PayBill option to complete payment.", type: "info" });
        this.mpesaMethod = "PAYBILL"
      }
    }
  },
  watch: {
    "form.amount"(newVal) {
      if (newVal) {
        this.getTotal();
      }
    },
  },
  methods: {
    toggleMPesaMethod(method) {
      this.mpesaMethod = method;
    },
    objectToParam(obj) {
      var str = [];
      let item = "";
      for (var p in obj) item = encodeURIComponent(p);
      item.concat("=", encodeURIComponent(obj[p]));
      str.push(item);
      return str.join("&");
    },
    loadAuthDetails(payload) {
      let checkoutKeys = this.$store.getters.getCheckoutKeys;
      if (checkoutKeys.signature && checkoutKeys.checkoutID) {
        payload.signature = checkoutKeys.signature;
        payload.checkout_id = checkoutKeys.checkoutID;
      } else {
        payload.public_key = this.$store.getters.getPublicKey;
      }
      return payload;
    },
    sendRequest() {
      let self = this;
      let loader = self.$loading.show();

      this.form = this.loadAuthDetails(this.form);
      this.form.device_fingerprint = this.deviceFingerprint;
      var payload = JSON.parse(JSON.stringify(this.form));
      payload.phone_number = this.phoneNumber;
      if (this.mpesaMethod === "PAYBILL") {
        payload.invoice_gen_only = true;
      }
      axios
        .post("payment/collection/", payload)
        .then((resp) => {
          loader.hide();
          let invoice_id = resp.data.invoice.invoice_id;
          if (self.mpesaMethod === "PAYBILL") {
            self.$router.push(
              "/mpesa-paybill/" +
                invoice_id +
                "?amount=" +
                resp.data.invoice.value
            );
          } else {
            self.$router.push(
              "/results/" +
                invoice_id +
                "?" +
                self.objectToParam(self.$route.query)
            );
          }
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          loader.hide();
          Sentry.captureException({
            resp: JSON.stringify(err),
            amount: self.form.amount,
            phone_number: self.form.phone_number,
            host: self.host,
            businessID: self.form.public_key,
            channel: "M-PESA",
          });
        });
    },
    onSubmit() {
      let self = this;
      self.$validate().then((success) => {
        if (success) {
          self.sendRequest();
        }
      });
    },
    getTotal() {
      let self = this;
      let loader = self.$loading.show({ opacity: 0 });
      let payload = {
        tarrif: this.form.mobile_tarrif,
        method: "M-PESA",
        currency: this.form.currency,
        amount: this.form.amount,
        public_key: this.$store.getters.getPublicKey,
      };
      axios
        .post("payment/get_amount_estimate/", payload)
        .then((resp) => {
          loader.hide();
          self.total = resp.data.total;
        })
        .catch((err) => {
          let message = utils.handleHTTPError(err);
          self.$store.commit("notify", { text: message, type: "error" });

          loader.hide();
          Sentry.captureException({
            resp: JSON.stringify(err),
            channel: "CARD-PAYMENT",
          });
        });
    },
  },
};
</script>