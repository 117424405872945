var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "choose-payment" }, [
    _c(
      "h1",
      { staticClass: "text-xl font-semibold text-gray-700 mb-6 text-center" },
      [_vm._v(" How would you like to pay? ")]
    ),
    _c(
      "div",
      { staticClass: "space-y-2" },
      _vm._l(_vm.getMethods, function (method) {
        return _c(
          "div",
          {
            key: method.id,
            staticClass:
              "p-4 bg-gray-300 hover:bg-gray-200 border-b border-gray-200 cursor-pointer rounded flex flex-row justify-between",
            class: {
              "bg-blue-600 text-blue-100": method.value === _vm.paymentMethod,
            },
            on: {
              click: function ($event) {
                return _vm.selectMethod(method.value)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "flex flex-col justify-center" },
              [
                _c(
                  "t-label",
                  {
                    staticClass: "text-gray-700 text-base cursor-pointer",
                    attrs: { uppercase: false },
                  },
                  [_vm._v(" " + _vm._s(method.label) + " ")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "flex flex-row justify-center" }, [
                _c("img", {
                  class: {
                    "h-8": method.value === "M-PESA",
                    "w-* h-8": method.value === "CARD-PAYMENT",
                    "w-22 h-8": method.value === "BITCOIN",
                  },
                  attrs: { src: method.image },
                }),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }