<template>
  <div class="flex flex-col">
    <div
      v-if="result.invoice.state == 'PENDING'"
      class="p-2 mb-2 bg-blue-600 rounded text-blue-100"
    >
      Please check
      <span class="font-bold">{{ result.invoice.account }}</span> for payment
      request.
    </div>
    <div
      v-if="result.invoice.state == 'FAILED'"
      class="p-2 mb-2 bg-red-600 rounded text-red-100"
    >
      {{ result.invoice.failed_reason }}
    </div>
    <div class="flex py-1">
      <div class="w-1/2">Provider</div>
      <div class="w-1/2 text-right">{{ result.invoice.provider }}</div>
    </div>
    <div class="flex py-1">
      <div class="w-1/2">Phone number</div>
      <div class="w-1/2 text-right">{{ result.invoice.account }}</div>
    </div>
    <div class="flex py-1">
      <div class="w-1/2">Status</div>
      <div class="w-1/2 text-right">{{ result.invoice.state }}</div>
    </div>
    <div class="flex py-1">
      <div class="w-1/2">Updated at</div>
      <div class="w-1/2 text-right">{{ result.invoice.updated_at | formatDate }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "MPesaResultsPage",
  props: {
    result: {
      required: true,
      type: Object,
    },
  },
  filters: {
    formatDate(input) {
      return moment(input).format("Do MMM, Y h:mm a");
    },
  }
};
</script>