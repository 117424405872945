 
<template>
  <label
    :class="{ uppercase: uppercase }"
    class="text-xs tracking-wide pr-4 font-semibold block text-gray-700 mb-1"
  >
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "TLabel",
  props: {
    uppercase: {
      type: Boolean,
      default: true,
    },
  },
};
</script>